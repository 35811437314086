
$mainBGColor: #f8f8f8;
$mainTextColor: #4f4f4f;

$primary-color: #032539;
$secondary-color: #ebebeb;
$tertiary-color: #ff3800;

$color-breves: #ffb91d;
$color-actualites: #fe551c;
$color-users: #a4a3a3;
$color-ads: #4db5ea;
$color-contacts: #00b74a;
$color-site: #39c0ed;

$lightgrey: #bdbdbd;
