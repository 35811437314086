@use 'vars';

header {
	background-color: vars.$mainBGColor;

	a:not(.main-title) {
		font-size: 1.1rem;
		font-weight: 300;
	}

	.navbar {
		background-color: white;
		height: 72px;

		.nav-link.router-link-active, .sidenav-link.router-link-active {
			border-radius: 5px;
		}

		.sidenav {
			--mdb-sidenav-background-color: #fff;
			--mdb-sidenav-data-hidden-false-transform: translateX(0%);
			--mdb-sidenav-data-color-light-color: hsla(0,0%,100%,0.6);
			--mdb-sidenav-data-right-true-transform: translateX(100%);
			--mdb-sidenav-data-slim-collapsed-true-width: 77px;
			--mdb-sidenav-menu-padding: 0.2rem;
			--mdb-sidenav-collapse-sidenav-link-font-size: 0.78rem;
			--mdb-sidenav-collapse-sidenav-link-height: 1.5rem;
			--mdb-sidenav-link-font-size: 0.89rem;
			--mdb-sidenav-link-padding-y: 1rem;
			--mdb-sidenav-link-padding-x: 1.5rem;
			--mdb-sidenav-collapse-sidenav-link-padding-left: 3.4rem;
			--mdb-sidenav-link-height: 3rem;
			--mdb-sidenav-link-border-radius: 5px;
			--mdb-sidenav-link-transition: all 0.3s linear;
			--mdb-sidenav-link-hover-background-color: #f6f9fc;
			--mdb-sidenav-link-active-focus-background-color: #f6f9fc;
			--mdb-sidenav-link-active-color: #3b71ca;
			--mdb-sidenav-subheading-font-size: 0.6rem;
			--mdb-sidenav-subheading-padding-y: 1rem;
			--mdb-sidenav-subheading-padding-x: 1.5rem;
			--mdb-sidenav-subheading-fw: 700;
			--mdb-sidenav-sm-link-pt: 0.4rem;
			--mdb-sidenav-sm-link-pb: 0.4rem;
			--mdb-sidenav-rotate-icon-margin-right: 0.8rem;
			--mdb-sidenav-rotate-icon-transition: transform 0.3s;
			--mdb-sidenav-light-color: hsla(0,0%,100%,0.6);
			top: 0;
			left: 0;
			transform: translateX(-100%);
			position: absolute;
			z-index: 1035;
			background-color: #fffffff0;
			overflow: hidden;
			width: 240px;
			height: 100%;
			box-shadow: 0 4px 12px 0 rgba(0,0,0,0.07),0 2px 4px rgba(0,0,0,0.05);
			color: vars.$mainTextColor;

			.sidenav-menu .main-title {
				padding-left: 13px;
				padding-top: 17px;
				border-bottom: 1px solid vars.$mainTextColor;
			}

			.sidenav-item {
				position: relative;
			}

			.sidenav-link {
				display: flex;
				align-items: center;
				cursor: pointer;
				font-size: var(--mdb-sidenav-link-font-size);
				padding: var(--mdb-sidenav-link-padding-y) var(--mdb-sidenav-link-padding-x);
				height: var(--mdb-sidenav-link-height);
				color: unset;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				border-radius: var(--mdb-sidenav-link-border-radius);
				transition: var(--mdb-sidenav-link-transition);
			}
		}
	}
}
