@use 'vars';

@use 'fonts';
@use 'theme';
@use 'helpers';

@use 'header';
@use 'footer';

body {
	font-size: 14px;
	background-color: vars.$mainBGColor;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: bold;
}

#app {
	margin-bottom: 123px;

	.card.register-form {
		.card-body {
			.md-form > i {
				width: 60px;
			}
		}
	}

	#user {
		.card-body.user {
			input, select {
				outline: none !important;
				background-color: inherit;
				font-style: inherit;
				border: none;
			}
			.input-edit  {
				border-bottom: 1px dotted lightgray !important;
			}
		}
	}

	.btn {
		font-weight: bold;
	}

	a.link-actualite:focus, a.link-actualite:active, a.link-actualite:focus, a.link-actualite:hover {
		.actualite .card {
			color: black;
			background-color: #dfdfdf;
		}
	}

	.actualite {
		.card {
			.actu-img {
				width: 100px;
				text-align: center;
			}

			.actu-img > img {
				max-width: 100px;
				max-height: 100px;
			}
		}

		a:focus, a:active, a:focus, a:hover {
			color: vars.$color-actualites;
		}

		.actu-img {
			margin-top: 4px;
		}
		.actualite-details .actu-desc {
			display: inline-block;
		}

		.actualite-details .actu-desc img {
			margin: 10px 10px 10px 0px;
		}
	}

	.ad {
		.card {
			background-color: transparent;
			color: white;

			img {
				max-height: 200px;
			}

			a {
				color: inherit;
			}
		}
	}

	a.link-breve:focus, a.link-breve:active, a.link-breve:focus, a.link-breve:hover {
		.breve .card {
			color: black;
			background-color: #dfdfdf;
		}
	}

	.breve {
		a:focus, a:active, a:focus, a:hover {
			color: vars.$color-breves;
		}
		.breve-header {
			font-weight: bold;
		}
		.fas {
			min-width: 35px;
			font-size: 1.3rem;
			line-height: unset;
		}
	}

	.contact-list {
		border: 1px solid vars.$lightgrey;
		border-radius: 4px;
		padding: 5px;

		.contact {
			border: 1px solid vars.$lightgrey;
			padding: 5px;
			border-radius: 4px;
			font-weight: normal !important;
			font-size: .9em;
		}
	}

	form label, form fieldset {
		min-width: 80px;
		font-weight: bold;
		margin-bottom: 0;
		margin-top: 10px;
	}

	ul.select {
		li:hover {
			background-color: vars.$lightgrey;
			color: white;
			cursor: pointer;
		}
	}

	.datatable-pagination-buttons {
		margin-left: 0;
	}

	.text-right {
		text-align: right;
	}

	.fs-7 {
		font-size: .9rem;
	}

	#about {
		p {
			overflow-wrap: anywhere;
		}
	}

	.dropzone {
		background: #fdfdfd;
		border-radius: 5px;
		border: 2px dashed #000;
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	input[type="file"] {
		opacity: 0;
		width: 0;
		cursor: pointer;
	}

	form {
		legend {
			float: none;
			width: auto;
			padding: 3px 6px;
			margin: 0;
			font-size: 1.2rem;
		}

		fieldset {
			display: block;
			margin-inline-start: 2px;
			margin-inline-end: 2px;
			padding-block-start: 0.35em;
			padding-inline-start: 0.75em;
			padding-inline-end: 0.75em;
			padding-block-end: 0.625em;
			min-inline-size: min-content;
			border: 1px groove black;
		}
	}
	@media (max-width: 768px) {
		.breve {
			.fas {
				min-width: 30px;
			}
		}
		.actualite {
			.actu-img {
				margin-top: 4px;
			}
		}
	}
}

.carousel-indicators {
	position: static;

	button {
		background-color: black!important;
	}
}

.ql-editor.ql-editor-front {
	li {
		list-style-type: inherit;
		padding-left: 0!important;
	}

	p {
		margin-bottom: 15px;
	}
}

.alert-fixed {
	display: block;
	top: 10px;
	right: 10px;
	bottom: unset;
	left: unset;
	transform: unset;
}

.opacity-50 {
	opacity: .5;
}

.terri-back-add-button {
	position: fixed;
	bottom: 10%;
	right: 0;
}

.custom-field {
	border: 1px solid black;
	border-top: none;
	margin: 21px 2px;
	padding: 8px;

	> h1, > h2, > h3, > h4, > h5 {
		font-size: 16px;
		margin: -16px -8px 0;
	}

	> h1 span, > h2 span, > h3 span, > h4 span, > h5 span {
		font-weight: bold;
		padding: 0px 8px;
		float: left;
	}

	> h1:before, > h2:before, > h3:before, > h4:before, > h5:before {
		border-top: 1px solid black;
		content: ' ';
		float: left;
		margin: 8px 2px 0 -1px;
		width: 12px;
	}

	> h1:after, > h2:after, > h3:after, > h4:after, > h5:after {
		border-top: 1px solid black;
		content: ' ';
		display: block;
		height: 24px;
		left: 2px;
		margin: 0 1px 0 0;
		overflow: hidden;
		position: relative;
		top: 8px;
	}
}

.form-control.form-calendar-label {
	width: 450px;
}

p a {
	text-decoration: underline;
}
p {
	margin-bottom: .4rem;
}

