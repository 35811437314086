
/* montserrat */
@font-face {
    font-family: 'Montserrat';
    src: url('../../public/fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
        url('../../public/fonts/montserrat/Montserrat-Regular.woff') format('woff'),
        url('../../public/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
	font-display: block;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../public/fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
        url('../../public/fonts/montserrat/Montserrat-Bold.woff') format('woff'),
		url('../../public/fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
	font-display: block;
}

body {
	font-family: 'Montserrat', sans-serif;
}
