
.cursor-pointer {
	cursor: pointer;
}

.title {
	margin: 10px 5px;
}

.text-transform-uppercase {
	text-transform: uppercase;
}
.font-weight-bold {
	font-weight: bold;
}

.elevate {
	z-index: 1000;
}

.spinner-border {
	width: 1rem;
	height: 1rem;
	border-width: .1em;
	margin-left: .7rem;
	margin-right: .7rem;
}

.fixed-button {
	position: fixed;
	right: 15px;
	bottom: 110px;
	z-index: 1030;
}

.card-title {
	text-decoration: underline;
}

.btn {
    padding: 5px 12px;
	font-size: 0.9rem;
}

@media (min-width: 576px) {
	.text-md-left {
		text-align: left!important;
	}
}

.outline-0:focus {
	outline: none !important;
}

.hide {
	visibility: hidden !important;
}

.badge {
    padding: 0.45em 0.65em;
	line-height: 0.9;
}

