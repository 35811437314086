@use 'vars';

a {
	color: vars.$mainTextColor;
}

.text-grey {
	color: #4f4f4f;
}
.text-bluelight {
	color: #2979ff !important;
}

.color-primary {
	color: vars.$primary-color !important;
}
.bg-primary {
	background-color: vars.$primary-color !important;
}
.color-secondary {
	color: vars.$secondary-color !important;
}
.bg-secondary {
	background-color: vars.$secondary-color !important;
}
.color-tertiary {
	color: vars.$tertiary-color !important;
}
.bg-tertiary {
	background-color: vars.$tertiary-color !important;
}
.border-color-tertiary {
	border-color: vars.$tertiary-color !important;
}

.btn {
	text-transform: none;

	&.btn-primary {
		background-color: vars.$primary-color;
	}
	&.btn-secondary {
		background-color: vars.$secondary-color;
		color: vars.$primary-color;
	}
	&.btn-tertiary {
		background-color: vars.$tertiary-color;
		color: white;
	}
}

.title {
	color: vars.$primary-color;
}

footer {
	background-color: vars.$primary-color;
	color: vars.$mainBGColor;

	a {
		color: inherit;
	}
}

.page-header {
	background-color: vars.$primary-color;
	color: white;
}

header {
	.router-link-active, &.router-link-exact-active {
		color: vars.$tertiary-color;
		font-weight: 600!important;
	}
}

.color-breves {
	color: vars.$color-breves;
}
.bg-breves {
	background-color: vars.$color-breves;
	color: white;
}
.btn-breves {
	background-color: vars.$color-breves;
	color: white;
}

.color-actualites {
	color: vars.$color-actualites;
}
.bg-actualites {
	background-color: vars.$color-actualites;
	color: white;
}
.btn-actualites {
	background-color: vars.$color-actualites;
	color: white;
}

.color-site {
	color: vars.$color-site;
}
.bg-site {
	background-color: vars.$color-site;
	color: white;
}
.btn-site {
	background-color: vars.$color-site;
	color: white;
}

.color-users {
	color: vars.$color-users;
}
.bg-users {
	background-color: vars.$color-users;
	color: white;
}
.btn-users {
	background-color: vars.$color-users;
	color: white;
}

.color-ads {
	color: vars.$color-ads;
}
.bg-ads {
	background-color: vars.$color-ads;
	color: white;
}
.btn-ads {
	background-color: vars.$color-ads;
	color: white;
}

.color-contacts {
	color: vars.$color-contacts;
}
.bg-contacts {
	background-color: vars.$color-contacts;
	color: white;
}
.btn-contacts {
	background-color: vars.$color-contacts;
	color: white;
}

.bg-grey {
	background-color: #eee;
	opacity: 1;
}

.form-switch {
	.form-check-input {
		background-color: grey !important;
	}

	.form-check-input:checked {
		background-color: vars.$primary-color !important;
	}

	.form-check-input:checked[type="checkbox"]:after {
		background-color: vars.$primary-color !important;
	}
}

#home {
	.home-card-top {
		background-color: #e0e0e0;
		padding-top: 10px;
		padding-bottom: 14px;
	}
}

.modal-dialog-scrollable {
	min-width: 90%;
	overflow-y: auto;
}

.badge-adm {
	background-color: vars.$color-actualites;
}
.badge-waiting {
	background-color: vars.$color-breves;
}
.badge-user {
	background-color: vars.$color-contacts;
}

.fa-remove {
	color: red !important;
}
